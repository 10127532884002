define('webshopProductsListCategoryView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'modalsConfirmationView',
  'webshopProductsCommonDefaultCategoryView',
  'template!webshopProductsListCategoryView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  ConfirmationView,
  DefaultCategoryView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-category-view ' + FieldView.prototype.className,

    regions: {
        defaultCategoryRegion: '.default-category-region'
    },

    modelEvents: {
        'change:defaultCategoryId': 'renderDefaultCategory'
    },

    onRender: function () {
      this.defaultCategoryView = new DefaultCategoryView({
        model: new Backbone.Model({
          id: this.model.get('defaultCategoryId'),
          code: this.model.get('defaultCategoryCode')
        })
      });
      this.defaultCategoryView.on('change', _.bind(function (model) {
        var title = '';
        var text = '';

        if(model.get('id') === '') {
          title = _.i18n('webshop.product.defaultCategory.delete.confirmation.title');
          text = _.i18n('webshop.product.defaultCategory.delete.confirmation.text').replace('%s', this.model.get('code'));
        } else {
          title = _.i18n('webshop.product.defaultCategory.update.confirmation.title');
          text = _.i18n('webshop.product.defaultCategory.update.confirmation.text').replace('%s', this.model.get('code'));
        }

        ConfirmationView.prototype.open(
        {
          title: title,
          text: text,
          onConfirm: _.bind(function () {
            this.defaultCategoryView.load(true);
            this.model.setDefaultCategory(model.get('id')).done(_.bind(function () {
              this.model.fetch();
              this.defaultCategoryView.load(true);
            }, this)).fail(_.bind(function () {
              this.defaultCategoryView.load(false);
            }, this));
          }, this)
        });
      }, this));
      this.getRegion('defaultCategoryRegion').show(this.defaultCategoryView);
    },

    renderDefaultCategory: function () {
      this.defaultCategoryView.model.set({
          id: this.model.get('defaultCategoryId'),
          code: this.model.get('defaultCategoryCode')
      });
    }
  });
});
