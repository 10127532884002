define('webshopLandingpagesDetailBodyUrlsListDomainView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'modalsConfirmationView',
  'modalsAlertView',
  'template!webshopLandingpagesDetailBodyUrlsListDomainView'
], function (
  App,
  Module,
  Marionette,
  _,
  Settings,
  ButtonView,
  ConfirmationView,
  AlertView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-urls-list-domain-view',

    regions: {
      refreshButtonRegion: '.refresh-button-region',
      updateButtonRegion: '.update-button-region'
    },

    modelEvents: {
      'change': 'render'
    },

    serializeData: function () {
      var data = {};
      data.mode = Settings.configValue('webshop.urls.mode');

      if (Settings.configValue('webshop.urls.mode') === 'new') {
        var url = '';
        var site = '';
        _.each(this.options.webshopConfig.attributes, function (data) {
          if (data.locale === this.model.get('locale')) {
            url = data.protocol + '://' + data.domain + '/';
            site = data.domain;
          }
        }, this);


        data.site = url;
        data.currentUrl = '';
        if (this.model.get('currentUrl') !== '')
          data.currentUrl = url + this.model.get('currentUrl');

        data.newUrl = '';
        if (this.model.get('newUrl') !== '')
          data.newUrl = url + this.model.get('newUrl');
      }

      if (Settings.configValue('webshop.urls.mode') === 'old') {
        data.domain = this.model.get('domain');
        data.url = this.model.get('url');
      }

      return data;
    },

    onRender: function () {
      if (Settings.configValue('webshop.urls.mode') === 'new') {
        this.refreshButtonView = new ButtonView({
          type: 'reload',
          title: _.i18n('common.refresh')
        });
        this.getRegion('refreshButtonRegion').show(this.refreshButtonView);
        this.refreshButtonView.on('click', _.bind(this.onReload, this));

        //---------------------------
        this.updateButtonView = new ButtonView({
          type: 'confirm',
          text: _.i18n('common.update'),
          title: _.i18n('common.update'),
          tabindex: 2
        });
        this.getRegion('updateButtonRegion').show(this.updateButtonView);
        this.updateButtonView.on('click', _.bind(this.onUpdate, this));
      }
    },

    onUpdate: function () {
      ConfirmationView.prototype.open(
      {
        title: _.i18n('webshop.urls.update.confirmation.title'),
        text: _.i18n('webshop.urls.update.confirmation.text').replace('%s', this.options.landingpageCode),
        onConfirm: _.bind(function () {
          this.trigger('loader', true);
          App.request('webshop.urls.update', 'landingpage', this.options.landingpageCode, this.model.get('locale'))
              .done(_.bind(function () {
                this.trigger('loader', false);
                this.reload();
              }, this)).fail(_.bind(function (response) {
                this.trigger('loader', false);
                var message = _.i18n('webshop.landingpage.urls.update.error.' + response.error);
                AlertView.prototype.open('', message);
              }, this));
        }, this)
      });
    },

    onReload: function () {
      this.trigger('loader', true);
      App.request('webshop.urls.get', 'landingpage', this.options.landingpageCode, [this.model.get('locale')])
      .done(_.bind(function (urls) {
        this.trigger('loader', false);
        if (urls.length > 0 && urls[0].locale === this.model.get('locale')) {
          this.model.set('currentUrl', urls[0].currentUrl);
          this.model.set('newUrl', urls[0].newUrl);
        }
      }, this));
    }
  });
});
