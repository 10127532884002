define('webshopRouter',[
  'app',
  'require',
  'module',
  'marionette',
  'underscore',
  'windows',
  'itemPagesNameMixin',
  'contentShowController'
], function (
  App,
  require,
  module,
  Marionette,
  _,
  Windows,
  ItemPagesNameMixin,
  ContentShowController
) {
  'use strict';

  var WebshopRouter = Marionette.AppRouter.extend({
    appRoutes: {
      'webshop': 'showProducts',
      'webshop/products': 'showProducts',
      'webshop/products/:code(/:tab)': 'showProduct',
      'webshop/categories': 'showCategories',
      'webshop/categories/tree': 'showCategoriesTree',
      'webshop/categories/:id(/:tab)': 'showCategory',
      'webshop/landingpages': 'showLandingpages',
      'webshop/landingpage/:id(/:tab)': 'showLandingpage'
    }
  });

  var API = {
    showProducts: function () {
      require([
        'webshopProductsListView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          var view = new View({
          });

          App.currentUrl = App.getCurrentRoute();
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_PRODUCTS);
        });
      });
    },

    showProduct: function (code, tabName) {
      require([
        'webshopProductsDetailView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          View.prototype.open(code, tabName);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_PRODUCTS);
        });
      });
    },

    showCategories: function (mode) {
      require([
        'webshopCategoriesListsView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          var view = new View({
            mode: mode ? mode : 'list'
          });

          App.currentUrl = App.getCurrentRoute();
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_CATEGORIES);
        });
      });
    },

    showCategoriesTree: function () {
      API.showCategories('tree');
    },

    showCategory: function (id, tabName) {
      require([
        'webshopCategoriesDetailView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          View.prototype.open(id, tabName);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_CATEGORIES);
        });
      });
    },

    showLandingpages: function () {
      require([
        'webshopLandingpagesListView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          var view = new View({});

          App.currentUrl = App.getCurrentRoute();
          App.regions.getRegion('main').currentView.getRegion('content').show(view);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_LANDINGPAGES);
        });
      });
    },

    showLandingpage: function (id, tabName) {
      require([
        'webshopLandingpagesDetailView'
      ], function (View) {
        ContentShowController.showContent().done(function () {
          View.prototype.open(id, tabName);
          App.execute('set:active:nav:item', ItemPagesNameMixin.WEBSHOP_LANDINGPAGES);
        });
      });
    }
  };

  App.on('before:start', function () {
    new WebshopRouter({
      controller: API
    });
  });

  module.exports = WebshopRouter;
});

