
define('template!webshopCommonRedirectionView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="title">' +
((__t = ( title )) == null ? '' : __t) +
'</div>\n<div class=\'category-button-region\'></div>\n<div class=\'product-button-region\'></div>';

}
return __p
};});

