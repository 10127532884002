define('webshopLandingpagesDetailBodyUrlsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'modalsConfirmationView',
  'modalsAlertView',
  'webshopLandingpagesDetailBodyUrlsListView',
  'template!webshopLandingpagesDetailBodyUrlsView',
  'entities/webshop/landingpages'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ButtonView,
  ConfirmationView,
  AlertView,
  ListView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-urls-view',

    initialize: function () {
      this.webshopConfig = this.options.webshopConfig;
    },

    ui: {
      loader: '.footer .loader-2'
    },

    regions: {
      listRegion: '.list-region',
      refreshButtonRegion: '.refresh-button-region',
      updateButtonRegion: '.update-button-region'
    },

    serializeData: function () {
      var data = {};
      var url = Settings.configValue('webshop.url');
      data.adminUrl = url + '/admin/default/jqadm/get/landingpage/' + this.model.getId();
      return data;
    },

    onShow: function () {
      this.listView = new ListView({
        webshopConfig: this.webshopConfig,
        landingpageCode: this.model.getCode()
      });

      this.listView.on('loader', function (value) {
        if (value)
          this.ui.loader.css('display', 'flex');
        else
          this.ui.loader.hide();
      }, this);

      this.getRegion('listRegion').show(this.listView);

      //---------------------------
      this.refreshButtonView = new ButtonView({
        type: 'reload',
        title: _.i18n('common.refresh')
      });
      this.getRegion('refreshButtonRegion').show(this.refreshButtonView);
      this.refreshButtonView.on('click', _.bind(this.reload, this));

      //---------------------------
      this.updateButtonView = new ButtonView({
        type: 'confirm',
        text: _.i18n('common.update'),
        title: _.i18n('common.update')
      });
      this.getRegion('updateButtonRegion').show(this.updateButtonView);
      this.updateButtonView.on('click', _.bind(this.onUpdate, this));
    },

    onUpdate: function () {
      ConfirmationView.prototype.open(
      {
        title: _.i18n('webshop.urls.update.confirmation.title'),
        text: _.i18n('webshop.urls.update.confirmation.text').replace('%s', this.model.get('code')),
        onConfirm: _.bind(function () {
          this.ui.loader.css('display', 'flex');
          App.request('webshop.urls.updateAll', 'landingpage', this.model.get('code'))
              .done(_.bind(function (response) {
                this.ui.loader.hide();
                var message = '<div class="webshop-landingpages-detail-body-urls-view-update-alert">';
                var hasAlert = false;
                _.each(response, function (data) {
                  if (data.error !== undefined) {
                    hasAlert = true;
                    message += '<div class="webshop-landingpages-detail-body-urls-view-update-alert-item">';
                    message += '<div class="locale">' + data.locale + ':</div> ';
                    message += '<div class="message">' + _.i18n('webshop.landingpage.urls.update.error.' + data.error) + '</div>';
                    message += '</div>';
                  }
                }, this);

                message += '</div>';

                if (hasAlert)
                  AlertView.prototype.open('', message);

                this.reload();
              }, this)).fail(_.bind(function (response) {
                this.ui.loader.hide();
                if (response.message)
                  AlertView.prototype.open('', response.message);
              }, this));
        }, this)
      });
    },

    reload: function () {
      this.ui.loader.css('display', 'flex');
      App.request('webshop.config').done(_.bind(function (config) {
        var locales = [];
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'landingpage', this.model.get('code'), locales).done(_.bind(function (urls) {
          this.listView.collection.reset(urls);
          this.ui.loader.hide();
        }, this));
      }, this));
    }
  });
});
