define('webshopProductsListToolsView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'modalsAlertView',
  'tableFieldView',
  'template!webshopProductsListToolsView'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  AlertView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-list-tools-view ' + FieldView.prototype.className,

    ui: {
      redirected: '.redirected',
      deleteCache: '.delete-cache-button'
    },

    events: {
      'click @ui.deleteCache': 'onDeleteCache'
    },

    onDeleteCache: function (e) {
      e.preventDefault();
      e.stopPropagation();

      this.ui.deleteCache.addClass('loading');
      this.model.clearCache()
      .done(_.bind(function () {
        this.ui.deleteCache.removeClass('loading');
      }, this))
      .fail(_.bind(function () {
        this.ui.deleteCache.removeClass('loading');
        AlertView.prototype.open('', _.i18n('common.unexpectedError'));
      }, this));
    },

    onRender: function () {
      if(this.model.get('redirected'))
        this.ui.redirected.css('display', 'flex');
      else
        this.ui.redirected.hide();
    }
  });
});
