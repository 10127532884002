
define('template!webshopProductsListFiltersEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="body">\n    <div class="code item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <input type="text" tabindex="1"/>\n    </div>\n    <div class="name item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n        <input type="text" name="name" tabindex="2">\n    </div>\n    <div class="status item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n        <div class="select-region region"></div>\n    </div>\n    ';
 if(showUnfinished) { ;
__p += '\n      <div class="unfinished item">\n          <div class="libelle">' +
((__t = ( _.i18n('webshop.products.unfinished') )) == null ? '' : __t) +
'</div>\n          <div class="select-region region"></div>\n      </div>\n    ';
 } ;
__p += '\n     ';
 if(showRedirection) { ;
__p += '\n          <div class="redirected item">\n              <div class="libelle">' +
((__t = ( _.i18n('webshop.products.redirected') )) == null ? '' : __t) +
'</div>\n              <div class="select-region region"></div>\n          </div>\n        ';
 } ;
__p += '\n    <div class="tag item">\n        <div class="libelle">' +
((__t = ( _.i18n('webshop.product.tag') )) == null ? '' : __t) +
'</div>\n        <div class="select-region region"></div>\n    </div>\n    <div class="category item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.category') )) == null ? '' : __t) +
'</div>\n        <div class="default-category-region"></div>\n    </div>\n    <div class="create-date item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.date') )) == null ? '' : __t) +
'</div>\n        <div class="dates">\n            <input type="date" class="date-from" name="webshopProductCreateDateStart" tabindex="6"/>\n            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"/></svg>\n            <input type="date" class="date-to" name="webshopProductCreateDateEnd" tabindex="7"/>\n        </div>\n    </div>\n    <div class="outdated item">\n        <div class="checkbox-region"></div>\n    </div>\n</div>\n<div class="footer">\n    <div class="cancel-region"></div>\n    <div class="reset-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

