define('webshopCategoriesFinderListFiltersView',[
  'module',
  'backbone',
  'underscore',
  'settings',
  'moment',
  'webshopCategoriesFinderListFiltersEditView',
  'webshopCategoriesFinderListFiltersQuickAccessView',
  'filtersBannerView'
], function(
    Module,
    Backbone,
    _,
    Settings,
    moment,
    EditView,
    QuickAccessView,
    FiltersBannerView
) {
  'use strict';

  Module.exports = FiltersBannerView.extend({
    editViewClass: EditView,
    quickAccessViewClass: QuickAccessView,

    initialize: function() {
      this.tableView = this.options.tableView;

      var commonFilters = Settings.get('webshopCategoriesFinderList', true);
      if(commonFilters) {
        this.model = new Backbone.Model(JSON.parse(commonFilters));
      } else {
        this.model = new Backbone.Model({
          code: '',
          name: '',
          status: '1'
        });
      }
      this.tableView.filters = this.model;

      FiltersBannerView.prototype.initialize.apply(this, arguments);
    },

    setCollection: function() {

      if (this.model.get('status') !== '') {
        var status = '';
        if (this.model.get('status') === '0')
          status = _.i18n('common.disabled');
        if (this.model.get('status') === '1')
          status = _.i18n('common.enabled');
        if (this.model.get('status') === '-1')
          status = _.i18n('common.review');

        this.collection.add(new Backbone.Model({
          id: 'status',
          libelle: _.i18n('common.status'),
          value: status
        }));
      }

      //----------------
      if(this.model.get('code') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'code',
          libelle: _.i18n('common.code'),
          value: this.model.get('code')
        }));
      }

      //----------------
      if(this.model.get('name') !== '') {
        this.collection.add(new Backbone.Model({
          id: 'name',
          libelle: _.i18n('common.name'),
          value: this.model.get('name')
        }));
      }

      //----------------
      FiltersBannerView.prototype.setCollection.apply(this, arguments);
    },

    fetch: function(model) {
      var needFetch = false;

      if(this.model.get('code') !== model.get('code'))
        needFetch = true;

      if(this.model.get('name') !== model.get('name'))
        needFetch = true;

      if (this.model.get('status') !== model.get('status'))
        needFetch = true;

      FiltersBannerView.prototype.fetch.apply(this, arguments);

      Settings.set('webshopCategoryFinderList', JSON.stringify(this.model.attributes), true);

      if(needFetch) {
        this.tableView.footerView.setCurrentPage(1);
        this.tableView.bodyView.fetch();
        this.tableView.footerView.fetch();
      }
    }
  });
});

