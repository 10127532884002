define('webshopCategoriesFinderListToolsView',[
  'module',
  'tableFieldView',
  'template!webshopCategoriesFinderListToolsView'
], function (
  Module,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-finder-list-tools-view ' + FieldView.prototype.className
  });
});
