define('webshopProductsFinderListFiltersEditView',[
  'app',
  'module',
  'backbone',
  'settings',
  'underscore',
  'selectView',
  'checkboxView',
  'webshopProductsCommonDefaultCategoryView',
  'filtersBannerEditView',
  'template!webshopProductsFinderListFiltersEditView'
], function(
    App,
    Module,
    Backbone,
    Settings,
    _,
    SelectView,
    CheckboxView,
    DefaultCategoryView,
    FiltersBannerEditView,
    Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'webshop-products-finder-list-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webshop.products.finder.list.filters.edit.title'),

    ui: _.extend({
      code: '.body .code input',
      name: '.body .name input',
      dateFrom: '.body .create-date .date-from',
      dateTo: '.body .create-date .date-to',
      category: '.body .category input'
    }, FiltersBannerEditView.prototype.ui),

    events: {
      'click @ui.category': 'onCategoryClick'
    },

    regions: _.extend({
      statusSelectRegion: '.status .select-region',
      tagSelectRegion: '.tag .select-region',
      outdatedRegion: '.outdated .checkbox-region',
      defaultCategoryRegion: '.default-category-region',
      unfinishedSelectRegion: '.unfinished .select-region'
    }, FiltersBannerEditView.prototype.regions),

    serializeData: function () {
      return {
        showUnfinished: Settings.configValue('webshop.products.showUnfinished') ? true : false
      };
    },

    onShow: function () {
      var collection = new Backbone.Collection();
      collection.add({id: '', value: _.i18n('common.all')});

      //----------------
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: '0', value: _.i18n('common.disabled')},
          {id: '1', value: _.i18n('common.enabled')}
        ]),
        tabindex: 3
      });
      this.statusSelectRegion.show(this.statusView);
      this.statusView.setSelected(this.model.get('status'));

      //----------------
      if (Settings.configValue('webshop.products.showUnfinished')) {
        this.unfinishedView = new SelectView({
          collection: new Backbone.Collection([
            {id: '', value: _.i18n('common.all')},
            {id: '0', value: _.i18n('common.no')},
            {id: '1', value: _.i18n('common.yes')}
          ]),
          tabindex: 4
        });
        this.unfinishedSelectRegion.show(this.unfinishedView);
        this.unfinishedView.setSelected(this.model.get('unfinished'));
      }

      //----------------
      App.request('webshop.products.tags').done(_.bind(function (tags) {
        _.each(tags.models, _.bind(function (tag) {
          var model = new Backbone.Model({
            id: tag.get('id'),
            value: _.i18n('webshop.product.tag.' + tag.get('code'))
          });
          collection.add(model);
          this.tagView = new SelectView({
            collection: collection,
            tabindex: 5
          });

          this.tagSelectRegion.show(this.tagView);
          this.tagView.setSelected(this.model.get('tag'));
        }, this));
      }, this));

      //----------------
      this.outdatedView = new CheckboxView({
        title: '',
        text: _.i18n('webshop.product.outdated'),
        value: this.model.get('outdated'),
        tabindex: 6
      });
      this.outdatedRegion.show(this.outdatedView);

      //----------------
      this.defaultCategoryView = new DefaultCategoryView({
        model: new Backbone.Model({
          id: this.model.get('defaultCategory').id,
          code: this.model.get('defaultCategory').code
        })
      });
      this.defaultCategoryView.on('change', _.bind(function (model) {
        this.model.get('defaultCategory').id = model.get('id');
        this.model.get('defaultCategory').code = model.get('code');
        this.defaultCategoryView.model.set('id', model.get('id'));
        this.defaultCategoryView.model.set('code', model.get('code'));
      }, this));

      this.getRegion('defaultCategoryRegion').show(this.defaultCategoryView);

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function () {
      this.ui.code.val(this.model.get('code'));
      this.ui.name.val(this.model.get('name'));
      if (this.model.get('dateFrom') !== '')
        this.ui.dateFrom.val(new Date(this.model.get('dateFrom')).toISOString().split('T')[0]);
      if (this.model.get('dateTo') !== '')
        this.ui.dateTo.val(new Date(this.model.get('dateTo')).toISOString().split('T')[0]);
    },

    onConfirm: function () {

      var unfinished = 0;
      if (Settings.configValue('webshop.products.showUnfinished'))
        unfinished = this.unfinishedView.getSelected().get('id');

      this.model.set({
        status: this.statusView.getSelected().get('id'),
        unfinished: unfinished,
        tag: this.tagView.getSelected().get('id'),
        code: this.ui.code.val().trim(),
        name: this.ui.name.val().trim(),
        dateFrom: this.ui.dateFrom.val() !== '' ? new Date(this.ui.dateFrom.val()).getTime() : '',
        dateTo: this.ui.dateTo.val() !== '' ? new Date(this.ui.dateTo.val()).getTime() : '',
        defaultCategory: {
          id: this.model.get('defaultCategory').id,
          code: this.model.get('defaultCategory').code
        },
        outdated: this.outdatedView.getValue()
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },

    onReset: function () {
      this.statusView.setSelected('');
      this.unfinishedView.setSelected('');
      this.tagView.setSelected('');
      this.ui.code.val('');
      this.ui.name.val('');
      this.ui.dateFrom.val('');
      this.ui.dateTo.val('');
      this.model.set('defaultCategory', {id: '', code: ''});
    }
  });  
});

