define('webshopProductsDetailBodyUrlsListView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'webshopProductsDetailBodyUrlsListDomainView',
  'entities/webshop/products'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  ButtonView,
  DomainView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'webshop-products-detail-body-urls-list-view',
    childView: DomainView,
    childViewOptions: function () {
      return {
        webshopConfig: this.webshopConfig,
        productCode: this.productCode
      };
    },

    childEvents: {
        'loader': function(view, value) {
            this.trigger('loader', value);
        }
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
      this.productCode = this.options.productCode;
      this.fetchRequest = null;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();

      App.request('webshop.config').done(_.bind(function (config) {
        var locales = [];
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'product', this.productCode, locales).done(_.bind(function (urls) {
          this.collection.add(urls);
        }, this));
      }, this));
    }
  });
});
