define('webshopCommonRedirectionView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'events',
  'buttonView',
  'webshopProductsFinderView',
  'webshopCategoriesFinderView',
  'modalsAlertView',
  'template!webshopCommonRedirectionView'
], function (
    App,
    Module,
    Marionette,
    _,
    Events,
    ButtonView,
    ProductsFinderView,
    CategoriesFinderView,
    AlertView,
    Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-common-redirection-view',

    initialize: function() {
      Events.push(this);
    },

    regions: {
      categoryButtonRegion: '.category-button-region',
      productButtonRegion: '.product-button-region'
    },

    onDestroy: function () {
      Events.pop(this);
    },

    serializeData: function() {
        return {
          title: _.i18n('wehshop.common.redirection.title')
        };
    },

    onShow: function() {
      this.categoryButtonView = new ButtonView({
        type: 'select',
        text: _.i18n('wehshop.common.redirection.category.button'),
        title: _.i18n('wehshop.common.redirection.category.button'),
        tabindex: 1
      });
      this.getRegion('categoryButtonRegion').show(this.categoryButtonView);
      this.categoryButtonView.on('click', _.bind(this.onSelectCategory, this));

      //--------------------
      this.productButtonView = new ButtonView({
        type: 'select',
        text: _.i18n('wehshop.common.redirection.product.button'),
        title: _.i18n('wehshop.common.redirection.product.button'),
        tabindex: 1
      });
      this.getRegion('productButtonRegion').show(this.productButtonView);
      this.productButtonView.on('click', _.bind(this.onSelectProduct, this));
    },

    onSelectCategory: function() {
      CategoriesFinderView.prototype.open({
        onSelected: _.bind(function (categories) {
          if (categories.length > 0) {
            var category = categories[0];
            this.model.redirect('category', category.getId(), 301).done(_.bind(function () {
              this.windowView.close();
              if (this.options.done)
                this.options.done();
            }, this)).fail(_.bind(function () {
              AlertView.prototype.open('', _.i18n('wehshop.common.redirection.category.error'));
              this.windowView.close();
              if (this.options.done)
                this.options.done();
            }, this));
          }
        }, this)
      });
    },

    onSelectProduct: function() {
      ProductsFinderView.prototype.open({
        onSelected: _.bind(function (products) {
          if(products.length > 0) {
            var product = products[0];
            this.model.redirect('product', product.getId(), 301).done(_.bind(function () {
              this.windowView.close();
              if(this.options.done)
                this.options.done();
            }, this)).fail(_.bind(function () {
              AlertView.prototype.open('', _.i18n('wehshop.common.redirection.product.error'));
              this.windowView.close();
              if (this.options.fail)
                this.options.fail();
            }, this));
          }
        }, this)
      });
    },

    // Static methods
    open: function (data) {
      require([
        'windows',
        'webshopCommonRedirectionView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          title: _.i18n('webshop.common.redirection')
        });
      });
    }
  });
});

