
define('template!webshopCategoriesFinderListFiltersEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n    <div class="code item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</div>\n        <input type="text" name="code" tabindex="1">\n    </div>\n    <div class="name item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.name') )) == null ? '' : __t) +
'</div>\n        <input type="text" name="name" tabindex="2">\n    </div>\n    <div class="status item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n        <div class="select-region region"></div>\n    </div>\n</div>\n<div class="footer">\n    <div class="cancel-region"></div>\n    <div class="reset-region"></div>\n    <div class="confirm-region"></div>\n</div>';

}
return __p
};});

