define('webshopLandingpagesDetailBodyTabsView',[
  'app',
  'module',
  'underscore', 
  'rolesMixin',
  'tabsView',
  'webshopLandingpagesDetailBodyLandingpageView',
  'webshopLandingpagesDetailBodyTextsView',
  'webshopLandingpagesDetailBodyUrlsView'
], function (
  App,
  Module,
  _,
  RolesMixin,
  TabsView
) {
  'use strict';

  Module.exports = TabsView.extend({
    fetch: function () {
      /*this.collection.push({
        name: 'landingpage',
        url: '',
        title: _.i18n('webshop.landingpages.landingpage.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_LANDINGPAGES_LANDINGPAGE,
        defaultSelected: true,
        view: 'webshopLandingpagesDetailBodyLandingpageView'
      });
      this.collection.push({
        name: 'texts',
        url: 'texts',
        title: _.i18n('webshop.landingpages.texts.tabTitle'),
        role: RolesMixin.DASHBOARD_WEBSHOP_LANDINGPAGES_TEXTS,
        view: 'webshopLandingpagesDetailBodyTextsView'
      });*/

      this.collection.push({
        name: 'urls',
        url: 'urls',
        title: _.i18n('common.urls'),
        role: RolesMixin.DASHBOARD_WEBSHOP_LANDINGPAGES_URLS,
        view: 'webshopLandingpagesDetailBodyUrlsView'
      });
    }
  });
});
