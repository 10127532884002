/**
 * Created by RKL on 13/08/2015.
 */
define('caccountShareView',[
  'module',
  'marionette',
  'underscore',
  'app',
  'jquery',
  'settings',
  'jqgridView',
  'stateMessages',
  'shareCreateEditLayoutView',
  'template!caccountShareTpl',
  'rolesMixin',
  'entities/caccounts/share'
], function (
  module,
  Marionette,
  _,
  App,
  $,
  Settings,
  JqGridView,
  StateMessages,
  CreateEditLayoutView,
  viewTpl,
  RolesMixin
) {
  'use strict';

  var CAccountShareTabView = JqGridView.extend({
    template: viewTpl,

    behaviors: {
      StateMessages: {
        behaviorClass: StateMessages
      }
    },

    ui: function() {
      return _.extend({
        jqGrid: '#jq-grid-share-' + this.options.share,
        jqGridPager: '#jq-grid-pager-share-' + this.options.share,
        addShare: '.js-add-share',
        deleteShare: '.js-delete-share',
        loader: '.js-loading'
      }, this.gridUi);
    },

    events: function() {
      return _.extend({
        'click @ui.deleteShare': 'onDeleteShare',
        'click @ui.addShare': 'onAddShare'
      }, this.gridEvents);
    },

    serializeData: function () {
      var data = this.options;
      var roles = Settings.get('cAccountRoles');
      if (roles[RolesMixin.SUPERUSER]) {
        data.displayNew = true;
      } else {
        data.displayNew = false;
      }
      return this.options;
    },

    pagination: function () {
      return {
        first: 0,
        rowCount: 0,
        pageSize: 50,
        currentPage: 1
      };
    },

    gridOptions: function(data) {
      var defaultsOptions = this.gridInitOptions(data),
        type = this.options.data.type,
        check = type === 'caccounts' || type === 'subcaccounts',
        options = {
          sortname: 'code',
          datatype: 'local',
          colModel: [
            {
              label: '',
              name: 'secId',
              key: true,
              hidden: true
            }, {
              label: _.i18n('common.code'),
              name: 'code',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'code'
            }, {
              label: _.i18n('common.name'),
              name: 'name',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'name'
            }, {
              label: _.i18n('cAccount.pimLastImport'),
              name: 'pimLastSuccess',
              classes: 'cell-link',
              formatter: this.dateTimeFormatter,
              search: true,
              index: 'pimLastSuccess',
              width: 60
            }, {
              label: _.i18n('cAccount.pimStatus'),
              name: 'pimStatus',
              classes: 'cell-link',
              formatter: this.successFailedFormatter,
              search: true,
              index: 'pimStatus',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + 'success' + ':' + _.i18n('common.success') +
                    ';' + 'failed' + ':' + _.i18n('common.failed')
              }
            }, {
              label: _.i18n('common.schedule'),
              name: 'pimSchedule',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'pimSchedule',
              width: 60
            }, {
              label: _.i18n('cAccount.priceLastImport'),
              name: 'priceLastSuccess',
              classes: 'cell-link',
              formatter: this.dateTimeFormatter,
              search: true,
              index: 'priceLastSuccess',
              width: 60
            }, {
              label: _.i18n('cAccount.priceStatus'),
              name: 'priceStatus',
              classes: 'cell-link',
              formatter: this.successFailedFormatter,
              search: true,
              index: 'priceStatus',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + 'success' + ':' + _.i18n('common.success') +
                    ';' + 'failed' + ':' + _.i18n('common.failed')
              }
            }, {
              label: _.i18n('common.schedule'),
              name: 'priceSchedule',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'priceSchedule',
              width: 60
            }, {
              label: _.i18n('cAccount.stockLastImport'),
              name: 'stockLastSuccess',
              classes: 'cell-link',
              formatter: this.dateTimeFormatter,
              search: true,
              index: 'stockLastSuccess',
              width: 60
            }, {
              label: _.i18n('cAccount.stockStatus'),
              name: 'stockStatus',
              classes: 'cell-link',
              formatter: this.successFailedFormatter,
              search: true,
              index: 'stockStatus',
              width: 60,
              stype: 'select',
              searchrules: {select: true},
              searchoptions: {
                value: ':' + _.i18n('common.all') + ';' + 'success' + ':' + _.i18n('common.success') +
                    ';' + 'failed' + ':' + _.i18n('common.failed')
              }
            }, {
              label: _.i18n('common.schedule'),
              name: 'stockSchedule',
              classes: 'cell-link',
              formatter: this.defaultFormatter,
              search: true,
              index: 'stockSchedule',
              width: 60
            }, {
              label: _.i18n('common.comment'),
              name: 'comment',
              classes: 'cell-link',
              width: 50,
              formatter: this.tooltipIconFormatter,
              search: true,
              index: 'comment'
            }, {
              label: _.i18n('common.orderEmail'),
              name: 'orderEmail',
              classes: 'cell-link',
              width: 50,
              formatter: this.defaultFormatter,
              search: true,
              index: 'orderEmail'
            }, {
              label: _.i18n('common.orderByEmail'),
              name: 'orderByEmail',
              classes: 'cell-link',
              width: 50,
              formatter: this.checkBoxFormatter,
              search: true,
              index: 'orderByEmail'
            }, {
              label: _.i18n('common.phone'),
              name: 'phone',
              classes: 'cell-link',
              width: 50,
              formatter: this.defaultFormatter,
              search: true,
              index: 'phone'
            }
          ],

          onSelectRow: _.bind(function (rowid, bool, e) {
            if (!$(e.target).hasClass('cell-delete') && e.type === 'click') {
              this.onEdit(rowid);
            }
          }, this)
        };

      if (check) {
        options.colModel.push({
          label: '',
          name: '',
          classes: 'delete-link js-delete-share',
          formatter: this.deleteIconFormatter,
          search: false,
          width: 50
        });
      }

      options = _.defaults(options, defaultsOptions);

      return options;
    },

    successFailedFormatter: function (cellvalue) {
      var htmlEl;
      if (cellvalue === 'success') {
        htmlEl = '<i class="mdi-action-done" style="color: green;" title="' + _.i18n('common.success') + '"></i>';
      } else if (cellvalue === 'failed') {
        htmlEl = '<i class="mdi-action-highlight-remove" style="color: red;" title="' + _.i18n('common.failed') + '"></i>';
      } else {
        htmlEl = '';
      }
      return htmlEl;
    },

    onEdit: function (secId) {
      var item = _.findWhere(this.gridArrayData, {secId: secId});
      var createEditView = new CreateEditLayoutView({
        customerModel: App.request('caccounts:share-model', item.item)
      });
      createEditView.show({
        title: _.i18n('common.edit')
      });
    },

    displayGrid: function (list, data) {
      var that = this,
        gridOptions;

      data.rowCount = 0;
      this.setFiltersName();
      gridOptions = this.gridOptions(data);

      that.ui.jqGrid.jqGrid(gridOptions);

      // activate the toolbar searching
      that.ui.jqGrid.jqGrid('filterToolbar');
      $(window).bind('resize', function() {
        that.ui.jqGrid.setGridWidth(that.$el.find('.jq-grid-table-page').width());
      });

      that.fetchGridData(list);
    },

    setFiltersName: function() {
      this.filtersName = 'caccountShare' + this.options.share + 'Filters';
    },

    fetchGridData: function(list) {
      this.gridArrayData = [];
      var type = this.options.share === 'to' ? 'caccountTo' : 'caccountFrom';

      _.each(list, _.bind(function (item, index) {
        var i = {
          number: index + 1,
          code: item[type].code,
          name: item[type].name,
          autoImport: item[type].autoImport,
          autoImportPrice: item[type].autoImportPrice,
          autoImportOutdated: item[type].autoImportOutdated,
          secId: item[type].secId,
          comment: item.comment,
          orderEmail: item.orderEmail,
          orderByEmail: item.orderByEmail,
          phone: item.phone,
          item: item,
          /*jshint camelcase: false */
          pimLastSuccess: item.cronPimData?item.cronPimData.last_success:null,
          pimStatus: item.cronPimData?item.cronPimData.status:null,
          pimSchedule: item.cronPimData?item.cronPimData.schedule:null,
          priceLastSuccess: item.cronPrice?item.cronPrice.last_success:null,
          priceStatus: item.cronPrice?item.cronPrice.status:null,
          priceSchedule: item.cronPrice?item.cronPrice.schedule:null,
          stockLastSuccess: item.cronStockData?item.cronStockData.last_success:null,
          stockStatus: item.cronStockData?item.cronStockData.status:null,
          stockSchedule: item.cronStockData?item.cronStockData.schedule:null
        };
        this.gridArrayData.push(i);
      }, this));

      // set the new data
      this.ui.jqGrid.jqGrid('clearGridData');
      this.ui.jqGrid.jqGrid('setGridParam', {data: this.gridArrayData});

      // refresh the grid
      this.ui.jqGrid.trigger('reloadGrid');
    },

    updatePagination: function() {},

    onAddShare: function() {
      this.trigger('onAddShare', this.options.share);
    },

    onDeleteShare: function(e) {
      var target = $(e.currentTarget),
        shareId = target.find('.cell-delete').data('row-id');

      this.trigger('onDeleteShare', shareId, this.options.share);
    }
  });

  module.exports = CAccountShareTabView;
});
