
define('template!webshopProductsDetailBodyUrlsV1DomainView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="domain">' +
((__t = ( domain )) == null ? '' : __t) +
'</div>\n</div>\n<div class="body">\n    <a class="url" href="' +
((__t = ( url )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( url )) == null ? '' : __t) +
'</a>\n</div>';

}
return __p
};});

