define('webshopProductsDetailBodyCategoriesHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'windows',
  'buttonView',
  'tableHeaderFieldView',
  'webshopCategoriesFinderView',
  'template!webshopProductsDetailBodyCategoriesHeaderToolsView'
], function (
  App,
  Module,  
  _,
  Windows,
  ButtonView,
  FieldView,
  CategoriesFinderView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-categories-header-tools-view ' + FieldView.prototype.className,

    regions: {
      addRegion: '.add-region'
    },

    onRender: function() {
      this.addButtonView = new ButtonView({
        type: 'add',
        text: _.i18n('common.add'),
        title: _.i18n('common.add')
      });

      this.addRegion.show(this.addButtonView);
      this.addButtonView.on('click', _.bind(this.onAdd, this));
    },

    onAdd: function() {
      CategoriesFinderView.prototype.open({
        title: _.i18n('wehshop.products.list.findDefaultCategory'),
        onSelected: _.bind(function (categories) {
          if (categories.length > 0) {
            this.tableView.model.addCategory(categories[0].getId()).done(_.bind(function () {
              this.tableView.reload();
              this.tableView.footerView.fetch();
            }, this));
          }
        }, this)
      });
    }
  });
});
