define('webshopProductsDetailBodyTextsLanguageView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'selectView',
  'template!webshopProductsDetailBodyTextsLanguageView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  SelectView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-texts-language-view',

    ui: {
      name: '.item.name input',
      slug: '.item.slug input',
      description: '.item.description textarea',
      extraDescription: '.item.extra-description textarea',
      metaTitle: '.item.meta-title input',
      metaDescription: '.item.meta-description textarea'
    },

    events: {
      'change @ui.name': 'changeName',
      'change @ui.slug': 'changeSlug',
      'change @ui.description': 'changeDescription',
      'change @ui.extraDescription': 'changeExtraDescription',
      'change @ui.metaTitle': 'changeMetaTitle',
      'change @ui.metaDescription': 'changeMetaDescription'
    },

    serializeData: function () {
      var data = {};
      data.language = this.model.get('language');
      data.name = this.model.get('product').getName(data.language);
      data.slug = this.model.get('product').getSlug(data.language);
      data.description = this.model.get('product').getDescription(data.language);
      data.metaTitle = this.model.get('product').getMetaTitle(data.language);
      data.metaDescription = this.model.get('product').getMetaDescription(data.language);

      data.showExtraDescription = false;
      data.extraDescription = '';

      if(Settings.configValue('webshop.products.showExtraDescription')) {
        data.showExtraDescription = true;
        data.extraDescription = this.model.get('product').getExtraDescription(data.language);
      }

      data.showSeo = false;
      if(Settings.configValue('webshop.products.showSeo'))
        data.showSeo = true;

      return data;
    },

    fetch: function () {
      this.model.get('product').fetch().done(_.bind(function () {
        this.render();
      }, this));
    },

    changeName: function () {
      this.model.get('product').setName(this.model.get('language'), this.ui.name.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    },

    changeSlug: function () {
      this.model.get('product').setSlug(this.model.get('language'), this.ui.slug.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    },

    changeDescription: function () {
      this.model.get('product').setDescription(this.model.get('language'), this.ui.description.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    },

    changeExtraDescription: function () {
      this.model.get('product').setExtraDescription(this.model.get('language'), this.ui.extraDescription.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    },

    changeMetaTitle: function () {
      this.model.get('product').setMetaTitle(this.model.get('language'), this.ui.metaTitle.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    },

    changeMetaDescription: function () {
      this.model.get('product').setMetaDescription(this.model.get('language'), this.ui.metaDescription.val())
      .always(_.bind(function () {
        this.fetch();
      }, this));
    }
  });
});
