
define('template!webshopLandingpagesDetailBodyView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tabs-region"></div>\n<div class="body-container">\n    <div class="tabs-body">\n        <div class="landingpage region"></div>\n        <div class="texts region"></div>\n        <div class="urls region"></div>\n    </div>\n</div>';

}
return __p
};});

