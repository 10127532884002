define('webshopLandingpagesDetailBodyUrlsListView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'webshopLandingpagesDetailBodyUrlsListDomainView',
  'entities/webshop/landingpages'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DomainView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-urls-list-view',
    childView: DomainView,
    childViewOptions: function () {
      return {
        webshopConfig: this.webshopConfig,
        landingpageCode: this.landingpageCode
      };
    },

    childEvents: {
      'loader': function (view, value) {
        this.trigger('loader', value);
      }
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
      this.landingpageCode = this.options.landingpageCode;
      this.fetchRequest = null;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();

      var locales = [];
      App.request('webshop.config').done(_.bind(function (config) {
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'landingpage', this.landingpageCode, locales).done(_.bind(function (urls) {
          this.collection.add(urls);
        }, this));
      }, this));
    }
  });
});
