
define('template!ordersDuplicateView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n  <div class="left">\n    <div class="user-region"></div>\n  </div>\n  <div class="right">\n    <div class="types item">\n      <div class="libelle">' +
((__t = ( _.i18n("common.type") )) == null ? '' : __t) +
'</div>\n      <div class="types-region"></div>\n    </div>\n    <div class="status item">\n      <div class="libelle">' +
((__t = ( _.i18n("common.status") )) == null ? '' : __t) +
'</div>\n      <div class="statuses-region"></div>\n    </div>\n    <div class="copy-customer-comments-region"></div>\n    <div class="copy-comments-region"></div>\n    <div class="copy-prices-region"></div>\n  </div>\n</div>\n<div class="footer">\n  <div class="left">\n    <div class="load">\n      <div class="loader-2"></div>\n      <div class="text"></div>\n    </div>\n  </div>\n  <div class="right">\n    <div class="cancel-region"></div>\n    <div class="confirm-region"></div>\n  </div>\n</div>';

}
return __p
};});

