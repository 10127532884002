define('webshopLandingpagesDetailBodyTextsView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'selectView',
  'webshopLandingpagesDetailBodyTextsLanguageView',
  'template!webshopLandingpagesDetailBodyTextsView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  SelectView,
  LanguageView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.CompositeView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-texts-view',
    childView: LanguageView,
    childViewContainer: '.languages-container',

    initialize: function () {
      this.webshopConfig = this.options.webshopConfig;
      this.collection = new Backbone.Collection();
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      _.each(this.options.webshopConfig.getLanguages(), _.bind(function (language) {
          this.collection.add({
            landingpage: this.model,
            language: language
          });
      }, this));
    }
  });
});
