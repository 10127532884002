define('webshopCategoriesFinderView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'events',
  'webshopCategoriesFinderListView',
  'template!webshopCategoriesFinderView'
], function (
    App,
    Module,
    Backbone,
    Marionette,
    _,
    Events,
    ListView,
    Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-finder-view',

    regions: {
      listRegion: '.list-region'
    },

    initialize: function () {
      Events.push(this);
    },

    onDestroy: function () {
      Events.pop(this);
    },

    onShow: function () {
      this.listView = new ListView({
        onSelected: _.bind(function (models) {
          if (this.options.onSelected)
            this.options.onSelected(models);
          this.windowView.close();
        }, this)
      });
      this.getRegion('listRegion').show(this.listView);
    },

    // Static methods
    open: function (data) {
      var title = _.i18n('webshop.categories.finder');

      if(data.title)
        title = data.title;

      require([
        'windows',
        'webshopCategoriesFinderView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          modal: true,
          width: 1024,
          height: 600,
          minWidth: 600,
          minHeight: 400,
          title: title
        });
      });
    }
  });
});

