define('webshopLandingpagesDetailHeaderView',[
  'module',
  'backbone',
  'marionette',
  'underscore',
  'dateUtils',
  'galleryView',
  'template!webshopLandingpagesDetailHeaderView'
], function (
  Module,
  Backbone,
  Marionette,
  _,
  DateUtils,
  GalleryView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-header-view',

    ui: {
      imageContainer: '.left .image',
      image: '.left .image img',
      name: '.name',
      code: '.code',
      createdAt: '.created-at .date',
      updatedAt: '.updated-at .date'
    },

    modelEvents: {
      'change:texts': 'renderContent',
      'change': 'renderContent'
    },

    serializeData: function() {
      var data = {};
      return data;
    },

    onRender: function () {
      this.renderContent();
    },

    renderImage: function () {
    },

    renderContent: function () {
      this.ui.code.html(this.model.getCode());
      this.ui.createdAt.html(DateUtils.toMDYHHMMFormat(this.model.get('createdAt')));
      this.ui.updatedAt.html(DateUtils.toMDYHHMMFormat(this.model.get('updatedAt')));
    }
  });
});
