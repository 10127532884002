
define('template!webshopCategoriesDetailBodyUrlsListDomainView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="header">\n    <div class="site">' +
((__t = ( site )) == null ? '' : __t) +
'</div>\n    <div class="refresh-button-region"></div>\n    <div class="update-button-region"></div>\n</div>\n<div class="body">\n    <div class="item">\n        <div class="name">' +
((__t = ( _.i18n("webshop.urls.currentUrl") )) == null ? '' : __t) +
':</div>\n        <a class="url" href="' +
((__t = ( currentUrl )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( currentUrl )) == null ? '' : __t) +
'</a>\n    </div>\n    <div class="item">\n        <div class="name">' +
((__t = ( _.i18n("webshop.urls.newUrl") )) == null ? '' : __t) +
':</div>\n        <a class="url" href="' +
((__t = ( newUrl )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( newUrl )) == null ? '' : __t) +
'</a>\n    </div>\n</div>\n';

}
return __p
};});

