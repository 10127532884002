define('webshopCategoriesDetailBodyUrlsListDomainView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'settings',
  'buttonView',
  'modalsConfirmationView',
  'modalsAlertView',
  'template!webshopCategoriesDetailBodyUrlsListDomainView',
  'entities/webshop/webshop'
], function (
  App,
  Module,
  Marionette,
  _,
  Settings,
  ButtonView,
  ConfirmationView,
  AlertView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-categories-detail-body-urls-list-domain-view',

    regions: {
      refreshButtonRegion: '.refresh-button-region',
      updateButtonRegion: '.update-button-region'
    },

    modelEvents: {
        'change': 'render'
    },

    serializeData: function () {
      var data = {};

      var url = '';
      var site = '';
      _.each(this.options.webshopConfig.attributes, function (data) {
        if (data.locale === this.model.get('locale')) {
          url = data.protocol + '://' + data.domain + '/';
          site = data.domain;
        }
      }, this);


      data.site = url;
      data.currentUrl = '';
      if (this.model.get('currentUrl') !== '')
        data.currentUrl = url + this.model.get('currentUrl');

      data.newUrl = '';
      if (this.model.get('newUrl') !== '')
        data.newUrl = url + this.model.get('newUrl');

      return data;
    },

    onRender: function () {
      this.refreshButtonView = new ButtonView({
        type: 'reload',
        title: _.i18n('common.refresh')
      });
      this.getRegion('refreshButtonRegion').show(this.refreshButtonView);
      this.refreshButtonView.on('click', _.bind(this.reload, this));

      //---------------------------
      this.updateButtonView = new ButtonView({
        type: 'confirm',
        text: _.i18n('common.update'),
        title: _.i18n('common.update')
      });
      this.getRegion('updateButtonRegion').show(this.updateButtonView);
      this.updateButtonView.on('click', _.bind(this.onUpdate, this));
    },

    onUpdate: function() {
      ConfirmationView.prototype.open(
      {
        title: _.i18n('webshop.urls.update.confirmation.title'),
        text: _.i18n('webshop.urls.update.confirmation.text').replace('%s', this.options.categoryCode),
        onConfirm: _.bind(function () {
          this.trigger('loader', true);
          App.request('webshop.urls.update', 'category', this.options.categoryCode, this.model.get('locale'))
              .done(_.bind(function () {
                this.trigger('loader', false);
                this.reload();
              }, this)).fail(_.bind(function (response) {
                this.trigger('loader', false);
                AlertView.prototype.open('', _.i18n('webshop.category.urls.update.error.' + response.error));
              }, this));
        }, this)
      });
    },

    reload: function() {
      this.trigger('loader', true);
      App.request('webshop.urls.get', 'category', this.options.categoryCode, [this.model.get('locale')]).done(_.bind(function (urls) {
        this.trigger('loader', false);
        if(urls.length > 0 && urls[0].locale === this.model.get('locale')) {
          this.model.set('currentUrl', urls[0].currentUrl);
          this.model.set('newUrl', urls[0].newUrl);
        }
      }, this));
    }
  });
});
