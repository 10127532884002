define('webshopProductsCommonDefaultCategoryView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopCategoriesFinderView',
  'buttonView',
  'template!webshopProductsCommonDefaultCategoryView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  CategoriesFinderView,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-common-default-category-view',

    ui: {
      selectedValue: '.selected-value',
      value: '.value',
      selectButton: '.select-button-region',
      deleteButton: '.delete-button',
      loader: '.loader-2'
    },

    regions: {
      selectButtonRegion: '.select-button-region'
    },

    events: {
      'click @ui.value': 'openFinder',
      'click @ui.deleteButton': 'deleteCategory'
    },

    modelEvents: {
      'change:code': 'render',
      'change:id': 'render'
    },

    onRender: function () {
      if (this.model.get('code') !== '') {
        this.ui.value.html(this.model.get('code'));
        this.ui.selectedValue.css('display', 'flex');
        this.ui.selectButton.hide();
        this.ui.deleteButton.css('display', 'flex');
      } else {
        this.selectButtonView = new ButtonView({
          type: 'find'
        });
        this.getRegion('selectButtonRegion').show(this.selectButtonView);
        this.selectButtonView.on('click', _.bind(this.openFinder, this));

        this.ui.selectedValue.hide();
        this.ui.selectButton.css('display', 'flex');
        this.ui.deleteButton.hide();
      }
    },

    openFinder: function () {
      CategoriesFinderView.prototype.open({
        title: _.i18n('wehshop.products.list.findDefaultCategory'),
        onSelected: _.bind(function (categories) {
          if (categories.length > 0) {
            var model = new Backbone.Model({
              id: categories[0].get('id'),
              code: categories[0].get('code')
            });
            this.trigger('change', model);
          }
        }, this)
      });
    },

    deleteCategory: function () {
      var model = new Backbone.Model({
        id: '',
        code: ''
      });
      this.trigger('change', model);
    },

    load: function(value) {
      if (value) {
        this.ui.loader.css('display', 'flex');
      } else {
        this.ui.loader.hide();
      }
    }
  });
});

