define('webshopCategoriesFinderListHeaderToolsView',[
  'app',
  'module',
  'underscore',
  'orderUtils',
  'buttonView',
  'tableHeaderFieldView',
  'template!webshopCategoriesFinderListHeaderToolsView'
], function (
  App,
  Module,  
  _,
  OrderUtils,
  ButtonView,
  FieldView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-categories-finder-list-header-tools-view ' + FieldView.prototype.className,

    regions: {
      selectRegion: '.select-region'
    },

    onRender: function() {
      this.listenTo(this.tableView.selectedCategories, 'change', _.bind(function() {
        this.renderSelectButton();
      }, this));

      this.selectButtonView = new ButtonView({
        type: 'select',
        text: _.i18n('common.select'),
        title: _.i18n('common.select')
      });
      this.selectRegion.show(this.selectButtonView);
      this.selectButtonView.on('click', _.bind(this.onConfirm, this));
      this.selectButtonView.enabled(false);
    },

    renderSelectButton: function() {
      if(this.tableView.selectedCategories.get('categories').length > 0)
        this.selectButtonView.enabled(true);
      else
        this.selectButtonView.enabled(false);
    },

    onConfirm: function() {
      this.tableView.onConfirm();
    }
  });
});
