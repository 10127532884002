define('webshopLandingpagesDetailBodyLandingpageView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'selectView',
  'checkboxView',
  'webshopLandingpagesDetailBodyLandingpageIndexesView',
  'template!webshopLandingpagesDetailBodyLandingpageView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  SelectView,
  CheckboxView,
  IndexesView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-Landingpages-detail-body-Landingpage-view',

    ui: {
      parentCategory: '.parent-category input'
    },

    regions: {
      indexesRegion: '.indexes-region'
    },

    serializeData: function () {
      var data = {};
      return data;
    },

    onRender: function () {
      this.indexesView = new IndexesView({
        product: this.model,
        webshopConfig: this.options.webshopConfig
      });
      this.indexesRegion.show(this.indexesView);
    }
  });
});
