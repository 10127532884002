
define('template!webshopCategoriesDetailBodyUrlsView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="body">\n    <div class="list-region"></div>\n    <div class="admin">\n      <div class="title">' +
((__t = ( _.i18n("webshop.webshop-administration-page") )) == null ? '' : __t) +
'</div>\n      <div class="url">\n        <a href="' +
((__t = ( adminUrl )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( adminUrl )) == null ? '' : __t) +
'</a>\n      </div>\n    </div>\n</div>\n<div class="footer">\n    <div class="left">\n          <div class="loader-2"></div>\n      </div>\n    <div class="right">\n        <div class="refresh-button-region"></div>\n        <div class="update-button-region"></div>\n    </div>\n</div>';

}
return __p
};});

