
define('template!adminHtmlTemplatesCreateEditTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="detailed-info-form-container">\n  <div class="saving-label"></div>\n  <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n    <div class="error-msg"></div>\n  </div>\n  <div class="form-horizontal">\n    <div class="form-inputs-container">\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.subject') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 subject-region">\n        </div>\n      </div>\n      <div class="form-group">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.content') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9 content-region">\n        </div>\n      </div>\n      <div class="form-group" data-field-name="templateType">\n        <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-9">\n          <input type="text" class="form-control js-info-input js-popup-input" data-field-name="templateType" value="" placeholder="' +
((__t = ( _.i18n('common.type') )) == null ? '' : __t) +
'">\n        </div>\n      </div>\n      <div class="row">\n        <div class="pull-right p-r-15">\n          <button class="btn btn-default btn-flat cancel-b js-cancel-popup">' +
((__t = ( _.i18n('common.cancel') )) == null ? '' : __t) +
'</button>\n          <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'</button>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>';

}
return __p
};});

