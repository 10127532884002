define('webshopCategoriesFinderListFiltersEditView',[
  'module',
  'backbone',
  'underscore',
  'selectView',
  'filtersBannerEditView',
  'template!webshopCategoriesFinderListFiltersEditView'
], function(
  Module,
  Backbone,
  _,
  SelectView,
  FiltersBannerEditView,
  Tpl
) {
  'use strict';
  
  Module.exports = FiltersBannerEditView.extend({
    template: Tpl,       
    className: 'webshop-categories-finder-list-edit-view ' + FiltersBannerEditView.prototype.className,
    windowTitle: _.i18n('webshop.categories.finder.list.filters.edit.title'),

    ui: _.extend({
      code: '.body .code input',
      name: '.body .name input'
    }, FiltersBannerEditView.prototype.ui),

    regions: _.extend({
      statusSelectRegion: '.status .select-region'
    }, FiltersBannerEditView.prototype.regions),

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      FiltersBannerEditView.prototype.initialize.apply(this, arguments);
    },

    onShow: function () {
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: '0', value: _.i18n('common.disabled')},
          {id: '1', value: _.i18n('common.enabled')},
          {id: '-1', value: _.i18n('common.review')}
        ]),
        tabindex: 3
      });
      this.statusSelectRegion.show(this.statusView);
      this.statusView.setSelected(this.model.get('status'));

      //----------------
      FiltersBannerEditView.prototype.onShow.apply(this, arguments);
    },

    onRender: function () {
      this.ui.code.val(this.model.get('code'));
      this.ui.name.val(this.model.get('name'));

      //----------------
      FiltersBannerEditView.prototype.onRender.apply(this, arguments);
    },

    onConfirm: function () {
      this.model.set({
        status: this.statusView.getSelected().get('id'),
        code: this.ui.code.val().trim(),
        name: this.ui.name.val().trim()
      });

      FiltersBannerEditView.prototype.onConfirm.apply(this, arguments);
    },
    onReset: function () {
      this.statusView.setSelected('');
      this.ui.code.val('');
      this.ui.name.val('');
    }
  });  
});

