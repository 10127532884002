define('webshopCategoriesFinderListView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'windows',
  'tableView',
  'webshopCategoriesListImageView',
  'webshopCategoriesListInfosView',
  'webshopCategoriesFinderListFiltersView',
  'webshopCategoriesFinderListToolsView',
  'webshopCategoriesFinderListHeaderToolsView',
  'entities/webshop/categories'
], function (
  Module,
  _,
  App,
  Backbone,
  Windows,
  TableView,
  ImageView,
  InfosView,
  FiltersView,
  ToolsView,
  HeaderToolsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-categories-finder-list-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,
    selectable: true,
    multiSelect: false,

    init: function() {
      this.filters = new Backbone.Model({
        code: '',
        name: '',
        status: '1'
      });

      this.selectedCategories = new Backbone.Model({categories: []});
    },

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });

      fields.push({
        name: 'Infos',
        label: _.i18n('common.category'),
        className: 'infos',
        view: InfosView
      });

      fields.push({
        name: 'headerTools',
        className: 'header-tools',
        view: ToolsView,
        headerView: HeaderToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      if(this.fetchRequest)
        this.fetchRequest.abort();

      this.fetchRequest = $.ajax({
        type: 'GET',
        url:  App.request('webshop.categories.search', params),
        success: _.bind(function (categories) {
          var models = [];

          _.each(categories, _.bind(function(item) {
            var model = App.request('webshop.category.model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.categories.count', params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      _.each(filters.attributes, function (value, key) {
        if(value !== '')
          params[key] = value;
      }, this);

      return params;
    },

    onRowDoubleClick: function(view) {
      this.selectedCategories.set('categories', [view.model]);
      this.onConfirm();
    },

    onSelected: function(rows) {
      var models = [];
      _.each(rows, function(row) {
        models.push(row.model);
      });
      this.selectedCategories.set('categories', models);
    },

    onConfirm: function() {
      if(this.options.onSelected)
        this.options.onSelected(this.selectedCategories.get('categories'));
    }
  });
});
