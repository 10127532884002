define('webshopLandingpagesListCategoryView',[
  'app',
  'module',
  'backbone',
  'underscore',
  'settings',
  'selectView',
  'tableFieldView',
  'webshopProductsCommonDefaultCategoryView',
  'template!webshopLandingpagesListCategoryView',
  'entities/webshop/categories'
], function (
  App,
  Module,
  Backbone,
  _,
  Settings,
  SelectView,
  FieldView,
  DefaultCategoryView,
  Tpl
) {
  'use strict';

  Module.exports = FieldView.extend({
    template: Tpl,
    className: 'webshop-landingpages-list-category-view ' + FieldView.prototype.className,

    regions: {
      defaultCategoryRegion: '.default-category-region'
    },

    modelEvents: {
      'change:defaultCategoryId': 'renderDefaultCategory'
    },

    onRender: function () {
      this.defaultCategoryView = new DefaultCategoryView({
        model: new Backbone.Model({
          id: this.model.get('defaultCategoryId'),
          code: this.model.get('defaultCategoryCode')
        })
      });
      this.defaultCategoryView.on('change', _.bind(function (model) {
        this.model.setDefaultCategory(model.get('id')).done(_.bind(function () {
          this.model.fetch();
        }, this));
      }, this));
      this.getRegion('defaultCategoryRegion').show(this.defaultCategoryView);
    },

    renderDefaultCategory: function () {
      this.defaultCategoryView.model.set({
        id: this.model.get('defaultCategoryId'),
        code: this.model.get('defaultCategoryCode')
      });
    }
  });
});
