define('webshopCategoriesDetailBodyProductsView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'windows',
  'tableView',
  'tableDatetimeFieldView',
  'webshopProductsDetailView',
  'webshopProductsListImageView',
  'webshopProductsListInfosView',
  'webshopProductsListSupplierView',
  'webshopProductsListPositionView',
  'webshopProductsListStatusView',
  'webshopProductsListTagsView',
  'webshopProductsListOutdatedView',
  'webshopProductsListFiltersView',
  'webshopCategoriesDetailBodyProductsToolsView',
  'webshopCategoriesDetailBodyProductsHeaderToolsView',
  'entities/webshop/products'
], function (
  Module,
  _,
  App,
  Backbone,
  Windows,
  TableView,
  DatetimeFieldView,
  DetailView,
  ImageView,
  InfosView,
  SupplierView,
  PositionView,
  StatusView,
  TagsView,
  OutdatedView,
  FiltersView,
  ToolsView,
  HeaderToolsView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-categories-detail-body-products-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',
    filtersViewClass: FiltersView,
    sortable: true,
    contextMenu: function() {
      var menu = {};

      if(this.sortActivated) {
        menu.moveToTop = {
          name: _.i18n('common.move-to-top'),
          callback: _.bind(function () {
            var products = [];
            _.each(this.getSelected(), function (view) {
              products.push(view.model);
            }, this);

            this.updatePosition(products, 0);
          }, this)
        };
      }

      return menu;
    },

    ui: {
      table: '.table-body-view'
    },

    setFields: function() {
      var fields = [];

      fields.push({
        name: 'Position',
        label: _.i18n('webshop.product.sort.relevance'),
        title: _.i18n('webshop.product.sort.relevance'),
        className: 'position',
        view: PositionView
      });

      fields.push({
        name: 'Image',
        className: 'image',
        view: ImageView
      });

      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'createdAt',
        className: 'date',
        view: DatetimeFieldView
      });

      fields.push({
        name: 'Infos',
        label: _.i18n('common.product'),
        className: 'infos',
        view: InfosView
      });

      fields.push({
        name: 'Supplier',
        label: _.i18n('product.supplier'),
        className: 'supplier',
        view: SupplierView
      });

      fields.push({
        name: 'Outdated',
        label: _.i18n('webshop.product.outdated'),
        className: 'outdated',
        view: OutdatedView
      });

      fields.push({
        name: 'Tags',
        label: _.i18n('webshop.product.tag'),
        className: 'tags',
        view: TagsView
      });

      fields.push({
        name: 'Status',
        label: _.i18n('common.status'),
        className: 'status',
        view: StatusView
      });

      fields.push({
        name: 'Tools',
        className: 'tools',
        headerView: HeaderToolsView,
        view: ToolsView
      });

      return fields;
    },

    fetch: function(first, pageSize, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      App.request('webshop.products.tags').done(_.bind(function () {
        if(this.fetchRequest)
          this.fetchRequest.abort();

        this.fetchRequest = $.ajax({
          type: 'GET',
          url:  App.request('webshop.category.products', this.model.getId(), params),
          success: _.bind(function (products) {
            var models = [];

            _.each(products, _.bind(function(item) {
              var model = App.request('webshop.product.model', item);
              models.push(model);
            }, this));

            defer.resolve(models);
          }, this)
        });
      }, this));

      return defer.promise();
    },

    fetchCount: function(filters) {
      var defer = $.Deferred();

      var params = this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.category.products.count', this.model.getId(), params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    getFiltersParams: function(filters) {
      var params = {};

      if(!this.filtersActivated)
        return params;

      _.each(filters.attributes, function (value, key) {
        if(key !== 'dateFrom' &&
            key !== 'dateTo' &&
            key !== 'defaultCategory') {
          if(value !== '')
            params[key] = value;
        }
      }, this);

      if(filters.get('dateFrom') !== '' && filters.get('dateTo') !== '') {
        params.dateFrom = filters.get('dateFrom');
        params.dateTo = filters.get('dateTo');
      }

      if (filters.get('defaultCategory').id !== '')
        params.categoryId = filters.get('defaultCategory').id;

      return params;
    },

    onRowDoubleClick: function(view) {
      Windows.open({
        view: _.bind(function () {
          return new DetailView({
            code: view.model.getCode()
          });
        }, this),
        id: 'webshop.product:' + view.model.getCode(),
        title: _.i18n('webshop.product.title'),
        width: '80%',
        height: '80%'
      });
    },

    updatePosition: function (products, position) {
      var ids = [];
      _.each(products, function (product) {
          ids.push(product.getId());
      });
      this.model.updateProductPosition(ids, position).done(_.bind(function () {
        this.reload();
      }, this)).fail(_.bind(function () {
        this.reload();
      }, this));
    }
  });
});
