
define('template!webshopProductsDetailBodyView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="tabs-region"></div>\n<div class="body-container">\n    <div class="tabs-body">\n        <div class="product region"></div>\n        <div class="texts region"></div>\n        <div class="slugs region"></div>\n        <div class="categories region"></div>\n        <div class="urls region"></div>\n        <div class="urlsV1 region"></div>\n        <div class="images region"></div>\n    </div>\n</div>';

}
return __p
};});

