define('webshopCategoriesFinderListFiltersQuickAccessView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'selectView',
  'filtersBannerQuickAccessView',
  'template!webshopCategoriesFinderListFiltersQuickAccessView',
  'entities/caccounts/share'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  SelectView,
  QuickAccessView,
  Tpl
) {
  'use strict';

  Module.exports = QuickAccessView.extend({
    template: Tpl,
    className: 'webshop-categories-finder-list-quick-access-view ' + QuickAccessView.prototype.className,

    ui: _.extend(QuickAccessView.prototype.ui, {
      code: '.code',
      name: '.name',
      status: '.status'
    }),

    events: _.extend(QuickAccessView.prototype.events, {
      'change @ui.code': 'onCodeChange',
      'change @ui.name': 'onNameChange'
    }),

    modelEvents: {
      'change:code': 'renderCode',
      'change:name': 'renderName',
      'change:status': 'renderStatus'
    },

    regions:  {
      statusSelectRegion: '.status-select-region'
    },

    initialize: function() {
      this.tableView = this.options.bannerView.options.tableView;
      QuickAccessView.prototype.initialize.apply(this, arguments);
    },

    onRender: function() {
      this.statusView = new SelectView({
        collection: new Backbone.Collection([
          {id: '', value: _.i18n('common.all')},
          {id: '0', value: _.i18n('common.disabled')},
          {id: '1', value: _.i18n('common.enabled')},
          {id: '-1', value: _.i18n('common.review')}
        ])
      });
      this.getRegion('statusSelectRegion').show(this.statusView);
      this.statusView.on('change', this.onStatusChange, this);
      this.statusView.setSelected(this.model.get('status'));

      this.renderCode();
      this.renderName();
      this.renderStatus();
    },

    renderCode: function() {
        this.ui.code.val(this.model.get('code'));
    },

    renderName: function() {
        this.ui.name.val(this.model.get('name'));
    },

    renderStatus: function() {
        this.statusView.setSelected(this.model.get('status'));
    },

    onCodeChange: function() {
      this.model.set('code', this.ui.code.val().trim());
      this.trigger('confirm', this.model);
    },

    onNameChange: function() {
      this.model.set('name', this.ui.name.val().trim());
      this.trigger('confirm', this.model);
    },

    onStatusChange: function() {
      this.model.set('status', this.statusView.getSelected().get('id'));
      this.trigger('confirm', this.model);
    }
  });
});
  
