define('webshopProductsDetailBodyView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'webshopProductsDetailBodyTabsView',
  'template!webshopProductsDetailBodyView',
  'entities/webshop/webshop'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  TabsView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-products-detail-body-view',

    regions: {
      tabsRegion: '.tabs-region',
      productRegion: '.product.region',
      textsRegion: '.texts.region',
      slugsRegion: '.slugs.region',
      categoriesRegion: '.categories.region',
      urlsRegion: '.urls.region',
      urlsV1Region: '.urlsV1.region',
      imagesRegion: '.images.region'
    },

    initialize: function() {
      this.views = [];
    },

    onShow: function() {
      App.request('webshop.config').done(_.bind(function(config) {
        this.webshopConfig = config;
        this.tabsView = new TabsView({
          model: this.model
        });
        this.getRegion('tabsRegion').show(this.tabsView);
        this.tabsView.setSelected(this.options.tabName);
        this.tabsView.on('select', _.bind(this.selectTab, this));
        this.selectTab(this.tabsView.getSelected());
      }, this));
    },

    selectTab: function(tab) {
      this.$el.find('.region').removeClass('selected');
      this.$el.find('.' + tab.get('name') + '.region').addClass('selected');

      require([tab.get('view')], _.bind(function (View) {
        if(!this.views[tab.get('name')]) {
          this.views[tab.get('name')] = new View({
            model: this.model,
            webshopConfig:this.webshopConfig
          });
          this.getRegion(tab.get('name') + 'Region').show(this.views[tab.get('name')]);
        }
      }, this));

      this.trigger('selectTab', tab);
    }
  });
});

