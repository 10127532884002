
define('template!webshopLandingpagesDetailBodyUrlsListDomainView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if(mode === 'new') { ;
__p += '\n  <div class="header">\n      <div class="site">' +
((__t = ( site )) == null ? '' : __t) +
'</div>\n      <div class="refresh-button-region"></div>\n      <div class="update-button-region"></div>\n  </div>\n  <div class="body">\n      <div class="item">\n          <div class="name">' +
((__t = ( _.i18n("webshop.urls.currentUrl") )) == null ? '' : __t) +
':</div>\n          <a class="url" href="' +
((__t = ( currentUrl )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( currentUrl )) == null ? '' : __t) +
'</a>\n      </div>\n      <div class="item">\n          <div class="name">' +
((__t = ( _.i18n("webshop.urls.newUrl") )) == null ? '' : __t) +
':</div>\n          <a class="url" href="' +
((__t = ( newUrl )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( newUrl )) == null ? '' : __t) +
'</a>\n      </div>\n  </div>\n';
 } else { ;
__p += '\n  <div class="header">\n      <div class="domain">' +
((__t = ( domain )) == null ? '' : __t) +
'</div>\n  </div>\n  <div class="body">\n      <a class="url" href="' +
((__t = ( url )) == null ? '' : __t) +
'" target="_blank">' +
((__t = ( url )) == null ? '' : __t) +
'</a>\n  </div>\n';
 } ;
__p += '\n';

}
return __p
};});

