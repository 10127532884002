define('modalsConfirmationView',[
  'app',
  'module',
  'marionette',
  'underscore',
  'events',
  'buttonView',
  'template!modalsConfirmationView'
], function(
  App,
  Module,
  Marionette,
  _,
  Events,
  ButtonView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'modals-confirmation-view',
    confirmWithEnter: true,

    ui: {
      body: '.body',
      footer: '.footer',
      load: '.footer .load',
      loadingText: '.footer .load > .text'
    },

    regions: {
      cancelRegion: '.cancel-region',
      confirmRegion: '.confirm-region'
    },

    initialize: function() {
      this.text = '';
      if(this.options.text)
          this.text = this.options.text;

      this.title = '';
      if(this.options.title)
        this.title = this.options.title;

      if(this.options.confirmWithEnter === false)
        this.confirmWithEnter = false;
    },

    onDestroy: function () {
      console.log('------ onDestroy');
      console.log(this);

      Events.pop(this);
    },

    serializeData: function() {
      var data = {};
      data.text = this.text;
      return data;
    },

    onShow: function() {
      Events.push(this);

      //----------------
      this.cancelView = new ButtonView({
        type: 'cancel',
        text: _.i18n('modals.confirmation.cancel'),
        title: _.i18n('modals.confirmation.cancel.title'),
        tabindex: 1
      });
      this.getRegion('cancelRegion').show(this.cancelView);
      this.cancelView.on('click', _.bind(this.onCancel, this));

      //----------------
      this.confirmButtonview = new ButtonView({
        type: 'confirm',
        text: _.i18n('modals.confirmation.confirm'),
        title: _.i18n('modals.confirmation.confirm.title'),
        tabindex: 2
      });
      this.confirmRegion.show(this.confirmButtonview);
      this.confirmButtonview.on('click', _.bind(this.onConfirm, this));

      //----------------
      this.ui.body.find('[tabindex]').on('focus', _.bind(this.onInputFocus, this));
    },

    onConfirm: function () {
      if(this.options.onConfirm)
        this.options.onConfirm();
      this.windowView.close();
    },

    onCancel: function() {
      if(this.options.onCancel)
        this.options.onCancel();
      this.windowView.close();
    },

    load: function(value, text) {
      if(value) {
        this.ui.load.css('display', 'flex');
        this.ui.loadingText.html(text);
      } else
        this.ui.load.hide();
    },

    onFocus: function() {
      this.bodyTabindex = 0;
      this.footerTabindex = 0;

      var tabs = this.ui.body.find('[tabindex]');

      var sortedTabs = _.sortBy(tabs, function(tab) {
        return parseInt($(tab).attr('tabindex'), 10);
      });

      var nextTab = _.find(sortedTabs, function(tab) {
        return parseInt($(tab).attr('tabindex'), 10) > this.bodyTabindex;
      }, this);

      if(nextTab) {
        this.bodyTabindex = parseInt($(nextTab).attr('tabindex'), 10);
        nextTab.focus();
        /*if($(nextTab).is(':input[type="text"]') ||
          $(nextTab).is(':input[type="number"]') ||
          $(nextTab).is(':input[type="date"]'))
        nextTab.select();*/
      }
    },

    onInputFocus: function(e) {
      this.bodyTabindex = parseInt($(e.currentTarget).attr('tabindex'), 10);
      this.footerTabindex = 0;
    },

    onKeyDown: function(e) {
      if (e.which === 13 && this.confirmWithEnter) {
        e.preventDefault();
        e.stopPropagation();
        this.onConfirm();
        return false;
      }

      if (e.which === 9) {
        e.preventDefault();
        e.stopPropagation();
        var bodyTabs = this.ui.body.find('[tabindex]');
        var footerTabs = this.ui.footer.find('[tabindex]');

        var sortedTabs = _.sortBy(bodyTabs, function(tab) {
          return parseInt($(tab).attr('tabindex'), 10);
        });

        var nextTab = _.find(sortedTabs, function(tab) {
          return parseInt($(tab).attr('tabindex'), 10) > this.bodyTabindex;
        }, this);

        if(nextTab)
          this.bodyTabindex = parseInt($(nextTab).attr('tabindex'), 10);

        if(!nextTab) {
          sortedTabs = _.sortBy(footerTabs, function(tab) {
            return parseInt($(tab).attr('tabindex'), 10);
          });

          nextTab = _.find(sortedTabs, function(tab) {
            return parseInt($(tab).attr('tabindex'), 10) > this.footerTabindex;
          }, this);

          if(nextTab)
            this.footerTabindex = parseInt($(nextTab).attr('tabindex'), 10);
          else {
            this.onFocus();
            return false;
          }
        }

        nextTab.focus();
        /*if($(nextTab).is(':input[type="text"]') ||
            $(nextTab).is(':input[type="number"]') ||
            $(nextTab).is(':input[type="date"]'))
          nextTab.select();*/

        return false;
      }
    },

    // Static methods
    open: function (data) {
      var title = '';
      if(data.title)
          title = data.title;
      require([
        'windows',
        'modalsConfirmationView'
      ], function (Windows, View) {
        Windows.open({
          view: _.bind(function () {
            return new View(data);
          }, this),
          title: title,
          modal: true,
          maxWidth: 800
        });
      });
    }
  });
});
