define('webshopCategoriesDetailBodyUrlsListView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'settings',
  'webshopCategoriesDetailBodyUrlsListDomainView',
  'entities/webshop/categories',
  'entities/webshop/webshop'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  Settings,
  DomainView
) {
  'use strict';

  Module.exports = Marionette.CollectionView.extend({
    className: 'webshop-categories-detail-body-urls-list-view',
    childView: DomainView,
    childViewOptions: function () {
      return {
        webshopConfig: this.webshopConfig,
        categoryCode: this.categoryCode
      };
    },

    childEvents: {
      'loader': function (view, value) {
        this.trigger('loader', value);
      }
    },

    initialize: function () {
      this.collection = new Backbone.Collection();
      this.webshopConfig = this.options.webshopConfig;
      this.categoryCode = this.options.categoryCode;
      this.fetchRequest = null;
    },

    onShow: function () {
      this.fetch();
    },

    fetch: function () {
      this.collection.reset();

      var locales = [];
      App.request('webshop.config').done(_.bind(function (config) {
        _.each(config.attributes, function (data) {
          locales.push(data.locale);
        });
        App.request('webshop.urls.get', 'category', this.categoryCode, locales).done(_.bind(function (urls) {
          this.collection.add(urls);
        }, this));
      }, this));
    }
  });
});
