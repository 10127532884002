define('webshopLandingpagesDetailBodyLandingpageIndexView',[
  'app',
  'module',
  'backbone',
  'marionette',
  'underscore',
  'checkboxView',
  'template!webshopLandingpagesDetailBodyLandingpageIndexView'
], function (
  App,
  Module,
  Backbone,
  Marionette,
  _,
  CheckboxView,
  Tpl
) {
  'use strict';

  Module.exports = Marionette.LayoutView.extend({
    template: Tpl,
    className: 'webshop-landingpages-detail-body-landingpage-index-view',

    regions: {
      checkboxRegion: '.checkbox-region'
    },

    serializeData: function () {
      var data = {};
      data.lang = this.model.get('lang');
      return data;
    },

    onShow: function () {
      this.checkboxView = new CheckboxView({
        title: this.model.get('lang'),
        text: this.model.get('lang'),
        value: this.model.get('value'),
        onChange: _.bind(function (value) {
          var defer = $.Deferred();
          this.options.landingpage.setIndex(this.model.get('lang'), value).done(_.bind(function () {
            this.options.landingpage.fetch().done(_.bind(function () {
              this.checkboxView.setValue(this.options.landingpage.getIndex(this.model.get('lang')));
              defer.resolve();
            }, this));
          }, this)).fail(_.bind(function () {
            this.options.landingpage.fetch().done(_.bind(function () {
              defer.reject();
            }, this));
          }, this));
          return defer.promise();
        }, this)
      });
      this.checkboxRegion.show(this.checkboxView);
      this.checkboxView.on('click', _.bind(this.onClick, this));
    },

    onClick: function (view) {
      this.model.set('value', view.getValue());
    }
  });
});
