define('webshopLandingpagesListView',[
  'module',
  'underscore',
  'app',
  'backbone',
  'settings',
  'windows',
  'tableView',
  'tableDatetimeFieldView',
  'webshopLandingpagesListCategoryView',
  'webshopLandingpagesDetailView',
  'entities/webshop/landingpages'
], function (
  Module,
  _,
  App,
  Backbone,
  Settings,
  Windows,
  TableView,
  DatetimeFieldView,
  CategoryView,
  DetailView
) {
  'use strict';

  Module.exports = TableView.extend({
    className: 'webshop-landingpages-list-view ' + TableView.prototype.className,
    pageSize: 50,
    pageMode: 'pagination',

    setFields() {
      var fields = [];

      fields.push({
        name: 'Date',
        label: _.i18n('common.date'),
        bind: 'createdAt',
        className: 'date',
        view: DatetimeFieldView
      });

      fields.push({
        name: 'Date',
        label: _.i18n('common.domain'),
        bind: 'domain',
        className: 'domain'
      });

      fields.push({
        name: 'Locale',
        bind: 'locale',
        label: _.i18n('common.locale'),
        className: 'locale'
      });

      fields.push({
        name: 'Code',
        bind: 'code',
        label: _.i18n('common.code'),
        className: 'code'
      });

      fields.push({
        name: 'Type',
        bind: 'type',
        label: _.i18n('common.type'),
        className: 'type'
      });

      fields.push({
        name: 'Category',
        label: _.i18n('common.category'),
        className: 'category',
        view: CategoryView
      });

      return fields;
    },

    fetch: function(first, pageSize) {//, sortField, sortDirection, filters) {
      var defer = $.Deferred();

      var params = {}; //this.getFiltersParams(filters);
      params.first = first;
      params.pageSize = pageSize;

      this.fetchRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.landingpages.search', params),
        success: _.bind(function (products) {
          var models = [];

          _.each(products, _.bind(function (item) {
            var model = App.request('webshop.landingpage.model', item);
            models.push(model);
          }, this));

          defer.resolve(models);
        }, this)
      });

      return defer.promise();
    },

    fetchCount: function() {//filters) {
      var defer = $.Deferred();

      var params = {}; //this.getFiltersParams(filters);

      if(this.fetchCountRequest)
        this.fetchCountRequest.abort();

      this.fetchCountRequest = $.ajax({
        type: 'GET',
        url: App.request('webshop.landingpages.count', params),
        success: _.bind(function (count) {
          defer.resolve(count);
        }, this)
      });

      return defer.promise();
    },

    onRowDoubleClick: function(view) {
      Windows.open({
        view: _.bind(function () {
          return new DetailView({
            id: view.model.getId()
          });
        }, this),
        id: 'webshop.landingpages:' + view.model.get('id'),
        title: _.i18n('webshop.landingpages.title'),
        width: '80%',
        height: '80%'
      });
    }
  });
});
