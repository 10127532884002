
define('template!webshopLandingpagesDetailBodyLandingpageView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="left">\n    <div class="parent-category item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.category') )) == null ? '' : __t) +
'</div>\n        <input type="text" name="webshopCategories" list="webshop-categories" class="value">\n    </div>\n    <div class="status item">\n        <div class="libelle">' +
((__t = ( _.i18n('common.status') )) == null ? '' : __t) +
'</div>\n        <div class="status-region value"></div>\n    </div>\n</div>\n<div class="right">\n    <div class="indexes-region"></div>\n</div>';

}
return __p
};});

