define('entities/webshop/landingpages',[
  'app',
  'backbone',
  'settings',
  'underscore',
  'backboneRelational'
], function (
  App,
  Backbone,
  Settings,
  _
) {
  'use strict';

  var app;
  if (Backbone.Relational.store._modelScopes && Backbone.Relational.store._modelScopes.length > 0) {
    app = {ns: Backbone.Relational.store._modelScopes[0]};
  } else {
    app = {ns: {}};
    Backbone.Relational.store.addModelScope(app.ns);
  }

  //-----------------

  app.ns.WebshopLandingpage = Backbone.RelationalModel.extend({
    idAttribute: 'id',
    fetch: function () {
      var params = {
        locale: Settings.get('lang')
      };

      params.id = this.getId();

      this.url = Settings.url('compuzz2', 'webshop/landingpage/get', params);

      return Backbone.Model.prototype.fetch.call(this);
    },

    getId: function () {
      return parseInt(this.get('id'), 10);
    },

    getCode: function () {
      return this.get('code');
    },

    getParentId: function () {
      return parseInt(this.get('parentId'), 10);
    },

    update: function(url, value) {
      var defer = $.Deferred();
      var data = {
        id: this.getId(),
        code: this.getCode(),
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/landingpage/' + url),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          defer.reject(data);
        }
      });
      return defer.promise();
    },
    getName: function (lang) {
      var name = this.getText('name', lang);
      return name;
    },

    setName: function (lang, value) {
      return this.setText('name', lang, value);
    },

    getDescription: function (lang) {
      return this.getText('long', lang);
    },

    setDescription: function (lang, value) {
      return this.setText('long', lang, value);
    },

    getMetaTitle: function (lang) {
      return this.getText('meta-title', lang);
    },

    setMetaTitle: function (lang, value) {
      return this.setText('meta-title', lang, value);
    },

    getMetaDescription: function (lang) {
      return this.getText('meta-description', lang);
    },

    setMetaDescription: function (lang, value) {
      return this.setText('meta-description', lang, value);
    },

    getSlug: function (lang) {
      if (lang)
        return this.getText('url', lang);
      else
        return this.getText('url');
    },

    setSlug: function (lang, value) {
      var defer = $.Deferred();

      if (this.getSlug() === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productCode: this.getCode(),
        lang: lang,
        value: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/landingpage/slug/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (data) {
          var d = null;
          if (data.responseJSON.data)
            d = data.responseJSON.data;
          defer.reject(d);
        }
      });
      return defer.promise();
    },

    getText: function (type, lang) {
      if (!lang)
        lang = Settings.get('lang');

      var text = '';
      if (this.get('texts')) {
        _.each(this.get('texts'), _.bind(function (t) {
          if (t.type === type && t.lang === lang)
            text = t.content;
        }, this));
      }
      return text;
    },

    setText: function (type, lang, value) {
      var defer = $.Deferred();

      if (this.getText(type, lang) === value) {
        defer.resolve();
        return defer.promise();
      }

      var data = {
        productCode: this.getCode(),
        code: type,
        lang: lang,
        content: value
      };

      $.ajax({
        type: 'PUT',
        url: Settings.url('compuzz2', 'webshop/landingpage/text/update'),
        data: JSON.stringify(data),
        processData: false,
        contentType: 'application/json',
        success: function () {
          defer.resolve();
        },
        error: function (error) {
          defer.reject(error);
        }
      });
      return defer.promise();
    }
  });

  App.reqres.setHandler('webshop.landingpage.model', function (data) {
    if (!data)
      data = {};
    return app.ns.WebshopLandingpage.findOrCreate(data);
  });

  App.reqres.setHandler('webshop.landingpages.search', function (params) {
    return Settings.url('compuzz2', 'webshop/landingpages/search', params);
  });

  App.reqres.setHandler('webshop.landingpages.count', function (params) {
    return Settings.url('compuzz2', 'webshop/landingpages/count', params);
  });

  App.reqres.setHandler('webshop.landingpages.urls', function (params) {
    return Settings.url('compuzz2', 'webshop/landingpages/urls', params);
  });
});

